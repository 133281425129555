import React from "react";
import Page from "../components/Page";
import Space from "../components/elements/Space/Space";
import Layout from "../components/Layout";

const CompanyInfo = () => (
  <Page title='Company Info' description='' image='/favicon.png'>
    <Layout>
      {/* navigation space */}
      <Space mobile={15} table={15} desktop={15} />

      <section className="section ">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-md-10" style={{ textAlign: "center" }}>
              <h2>Company info</h2>
              <p className="lead text-center">
                Ragione Sociale: E-leads Srl<br />

                Sede Legale: Strada Statale Trossi 41, Verrone (BI)<br />

                Pec: e-leadssrl[at]pec.it<br />

                Codice Fiscale: IT02668420025 <br />

                R.E.A. BI 204055 <br />

                Partita IVA: IT02668420025<br />

                Capitale Sociale: € 10.000,00 i.v.<br />

                La società non è in stato di liquidazione<br />
                La società non è a socio unico<br />
              </p>
            </div>
          </div>


        </div>
      </section>
    </Layout>
  </Page>
);

export default CompanyInfo;
